<template>
  <card>
    <template slot="header">
      <p class="card-header-title">Contact preferences</p>
    </template>

    <template slot="body">
      <b-field
        v-for="pref in preferences"
        :key="pref.key"
        class="has-content-justified-between has-items-aligned-center"
      >
        <p class="control">{{ pref.desc }}</p>

        <b-switch
          v-model="metacopy[pref.key]"
          type="is-info"
          class="has-margin-left-100"
          @input="updatePreference(pref.key, $event)"
        />
      </b-field>
    </template>
  </card>
</template>

<script>
export default {
  name: "ContactPreferences",
  data() {
    return {
      metacopy: {},
      preferences: [
        {
          key: "sendProductInformation",
          desc: "Keep me informed on product updates and all the latest news."
        },
        {
          key: "sendStatusReports",
          desc: "Keep me informed on service status and unplanned outages."
        },
        {
          key: "sendOffers",
          desc: "Send me exclusive offers."
        }
      ]
    };
  },
  computed: {
    metadata() {
      return this.$store.getters["user/user"]();
    }
  },
  watch: {
    metadata() {
      this.copyMetadata();
    }
  },
  created() {
    this.copyMetadata();
  },
  methods: {
    copyMetadata() {
      this.metacopy = this.$_.cloneDeep(this.metadata);
      this.metacopy = this.$_.pick(this.metacopy, [
        "sendProductInformation",
        "sendStatusReports",
        "sendOffers"
      ]);
    },
    updatePreference(option, bool) {
      if (this.metacopy[option] === this.metadata[option]) return;
      this.$store
        .dispatch("user/updateProfile", {
          payload: {
            [option]: !!bool
          }
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            type: "is-danger",
            position: "is-bottom",
            queue: false
          });
        });
    }
  }
};
</script>
